/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    h2: "h2",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "[ ] header readable scale", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "[ ] mobile"), "\n", React.createElement(_components.li, null, "[ ] desktop"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "[ ] code blocks", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "[ ] syntax highlighting", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "[ ] dark"), "\n", React.createElement(_components.li, null, "[ ] light"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "[ ] copy to clipboard"), "\n", React.createElement(_components.li, null, "[ ] run code block (optional)"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "[ ] anchor tags"), "\n", React.createElement(_components.li, null, "[ ] table of contents", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "[ ] section highlighting"), "\n"), "\n"), "\n", React.createElement(_components.li, null, "[ ] definition syntax"), "\n", React.createElement(_components.li, null, "[ ] block quotes"), "\n", React.createElement(_components.li, null, "[ ] images"), "\n", React.createElement(_components.li, null, "[ ] videos"), "\n", React.createElement(_components.li, null, "[ ] iframes"), "\n"), "\n", React.createElement(_components.h2, null, "Examples"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://developer.mozilla.org/"
  }, "MDX Web Docs")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
